import './Home.css'
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Slider from './components/Slider/Slider'
import About from './components/About/About'
import Features from './components/Features/Features'
import Portfolio from './components/Portfolio/Portfolio'
import OurPakeg from './components/OurPakeg/OurPakeg'
// import { Helmet } from 'react-helmet'

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script')
  script.src = urlOfTheLibrary
  script.async = true
  document.body.appendChild(script)
}

function Home() {
  return (
    <React.Fragment>
      <Header />

      <Slider />

      <About />

      <Features />

      <Portfolio />

      <OurPakeg />

      <Footer />

      <div className="scroll-up">
        <a href="/#">
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
      {/* Call the function to add a library */}
      {/* {AddLibrary(
        '../../assets/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js'
      )} */}
      {AddLibrary('../../assets/js/vendor/jquery-1.11.2.min.js')}
      {AddLibrary('../../assets/js/vendor/bootstrap.min.js')}
      {AddLibrary('../../assets/js/jquery-easing/jquery.easing.1.3.js')}
      {AddLibrary('../../assets/js/wow/wow.min.js')}
      {AddLibrary('../../assets/js/plugins.js')}
      {AddLibrary('../../assets/js/main.js')}
      {AddLibrary(
        '../../assets/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js'
      )}
      {/* <Helmet>
        <script
          src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
          type="text/javascript"
        />
        <script
          src="../../assets/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js"
          type="text/javascript"
        />
      </Helmet> */}
    </React.Fragment>
  )
}

export default Home
