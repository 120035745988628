import CommandLink from '../../../../components/CommandLink/CommandLink'
import './Portfolio.css'

function Portfolio() {
  return (
    <>
      <section id="portfolio" className="portfolio">
        <div className="container">
          <div className="row">
            <div
              className="portfolio_content text-center  wow fadeIn"
              data-wow-duration="5s"
            >
              <div className="col-md-12">
                <div className="head_title text-center">
                  <h4>Délicieuse</h4>
                  <h3>Experience</h3>
                </div>

                <div className="main_portfolio_content">
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p1.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <span className="short_product_name_price">
                        <h6 className="short-product-name">Spaghetti</h6>
                        <p className="product_price great-product-price">
                          1000 / 2000 / 2500 fcfa
                        </p>
                        <p className="short-product-price">
                          1000 /2000
                          <br />
                          /2500 fcfa
                        </p>
                        <CommandLink label="Commander" command="Spaghetti" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p2.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">
                        Riz + sauce d'escargots
                      </h6>
                      <p className="product_price">2000 fcfa</p>
                      <CommandLink
                        label="Commander"
                        command="Riz + sauce d'escargots"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p3.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">
                        Poulet 1/2 frire ou grillé
                      </h6>
                      <p className="product_price">3500 fcfa</p>
                      <CommandLink
                        label="Commander"
                        command="Poulet 1/2 frire ou grillé"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p4.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">Pizza</h6>
                      <p className="product_price">7000 fcfa</p>
                      <CommandLink label="Commander" command="Pizza" />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p5.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">
                        Burger / Double Burger
                      </h6>
                      <p className="product_price">1000 / 2000 fcfa</p>
                      <CommandLink label="Commander" command="Burger" />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p6.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">
                        Boulettes de viande
                      </h6>
                      <p className="product_price">300 fcfa l'unité</p>
                      <CommandLink
                        label="Commander"
                        command="Boulettes de viande"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p7.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <h6 className="short-product-name">Brochette de boeuf</h6>
                      <p className="product_price">300 fcfa</p>
                      <CommandLink
                        label="Commander"
                        command="Brochette de boeuf"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-xs-6 single_portfolio_text">
                    <img src="assets/images/p8.png" alt="" />
                    <div className="portfolio_images_overlay text-center">
                      <span className="short_product_name_price">
                        <h6 className="short-product-name">Tilapia</h6>
                        <p
                          className="product_price great-product-price"
                          id="tilapia_price"
                        >
                          3000 / 3500
                          <br />/ 4000 fcfa
                        </p>
                        <p className="short-product-price">
                          3000 /3500
                          <br />
                          /4000 fcfa
                        </p>
                        <CommandLink label="Commander" command="Tilapia" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio
