import './About.css'

function About() {
  return (
    <>
      <section id="abouts" className="abouts">
        <div className="container">
          <div className="row">
            <div className="abouts_content">
              <div className="col-md-6">
                <div
                  className="single_abouts_text text-center wow slideInLeft"
                  data-wow-duration="1s"
                >
                  <img src="assets/images/ab.png" alt="" />
                </div>
              </div>

              <div className="col-md-6">
                <div
                  className="single_abouts_text wow slideInRight"
                  data-wow-duration="1s"
                >
                  <h4>A propos de nous</h4>
                  <h3>NOUS SOMMES UN BAR RESTAURANT</h3>
                  <p>
                    Le restaurant TONTON LA JOIE est un restaurant qui fait de
                    la cuisine africaine et d'ailleurs, nous faisons du
                    fast-food et avons un bar très bien garni pour toute
                    demande.
                  </p>

                  <p>
                    Nous faisons du service traiteur, du commander-emporter ou
                    livré. Nous avons également un grand espace et salle équipés
                    pour tout ceux qui préfèrent le surplace. Et pour les
                    entreprises n'hésitez pas à nous contacter pour vos
                    déjeuners et autres..
                  </p>

                  <a href="#footer_widget" className="btn btn-primary">
                    Contactez-nous maintenant
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
