import CommandLink from '../../../../components/CommandLink/CommandLink'
import './OurPakeg.css'

function OurPakeg() {
  return (
    <>
      <section id="ourPakeg" className="ourPakeg">
        <div className="container">
          <div className="main_pakeg_content">
            <div className="row">
              <div className="head_title text-center">
                <h4>Savoureux</h4>
                <h3>Nos Packages</h3>
              </div>

              <div
                id="boisson"
                className="single_pakeg_one text-right wow rotateInDownRight"
              >
                <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Boissons</h4>
                    </div>

                    <ul>
                      <li>
                        <span className="full-text">
                          Cook's | LOUIS ENGLER | CHIARLI | Hugo Limone | Hugo
                          Rosé | CALIBER
                          VODKA..............................................................5000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            Cook's | LOUIS ENGLER | CHIARLI | Hugo Limone | Hugo
                            Rosé | CALIBER VODKA
                          </b>
                          <br />
                          5000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          WINKING
                          OWL...................................................................6000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>WINKING OWL</b>
                          <br />
                          6000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Arbor
                          Mist............................................................................7000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Arbor Mist</b>
                          <br />
                          7000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          CHARLES
                          SHAW..............................................................10000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>CHARLES SHAW</b>
                          <br />
                          10000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          UNOM
                          Margarita.............................................................11000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>UNOM Margarita</b>
                          <br />
                          11000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          LOS GANSOS | VUELTA | MERLOT ROSÉ | VINAS CHILENAS |
                          BORDEAUX
                          ......................................................................12000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            LOS GANSOS | VUELTA | MERLOT ROSÉ | VINAS CHILENAS |
                            BORDEAUX | BEAUJOLAIS NOUVEAU | Soir de PARIS
                          </b>{' '}
                          <br />
                          12000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          Punto
                          final........................................................................13000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Punto final</b>
                          <br />
                          13000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          PRIMITIVO EPICURO | BORDEAUX SUPÉRIEUR......14000 fcfa
                        </span>
                        <span className="short-text">
                          <b>PRIMITIVO EPICURO | BORDEAUX SUPÉRIEUR</b>
                          <br />
                          14000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          Colby Red | La Finca | BEL
                          ORMEAU...........................17000 fcfa
                        </span>
                        <span className="short-text">
                          <b>Colby Red | La Finca | BEL ORMEAU</b>
                          <br />
                          17000 fcfa
                        </span>
                      </li>

                      <li>
                        <span className="full-text">
                          CABERNET
                          SAUVIGNON...............................................20000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>CABERNET SAUVIGNON</b>
                          <br />
                          20000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          WINDSOR CANADIAN | AMERICAN VODKA | Canadian Springs |
                          CHRISTIAN BROTHERS...............................35000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            WINDSOR CANADIAN | AMERICAN VODKA | Canadian Springs
                            | CHRISTIAN BROTHERS
                          </b>
                          <br />
                          35000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Evan Williams | SPICED
                          RUM........................................45000 fcfa
                        </span>
                        <span className="short-text">
                          <b>Evan Williams | SPICED RUM</b>
                          <br />
                          45000 fcfa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="ourPakegCommandLink">
                <CommandLink
                  label="Commandez maintenant"
                  command="une de vos boissons"
                />{' '}
              </div>

              <div
                id="saladeVerte"
                className="single_pakeg_two text-left wow rotateInDownLeft"
              >
                <div className="col-md-6 col-sm-8">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Les salades </h4>
                    </div>

                    <ul>
                      <li>
                        <span className="full-text">
                          Salade
                          verte..............................................................1000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Salade verte</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Salade
                          composée.....................................................2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Salade composée</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Salade pomme de
                          terre............................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Salade pomme de terre</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Salade
                          niçoise..........................................................2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Salade niçoise</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Salade
                          d'avocat........................................................2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Salade d'avocat</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="single_pakeg_three text-left wow rotateInDownRight">
                <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Nos Plats</h4>
                    </div>

                    <ul>
                      <li id="spaghettiBlanc">
                        <span className="full-text">
                          Spaghetti rouge /
                          blanc....................................................1000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Spaghetti rouge / blanc</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li id="spaghettiEcreme">
                        <span className="full-text">
                          Spaghetti écrémé /
                          carbonara........................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Spaghetti écrémé / carbonara</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li id="spaghettiEcreme">
                        <span className="full-text">
                          Spaghetti
                          carbonara.........................................................2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Spaghetti carbonara</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                      <li id="spaghettiBolognaise">
                        <span className="full-text">
                          Spaghetti
                          bolognaise........................................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Spaghetti bolognaise</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Couscous blanc / au
                          gras..................................1000 / 1500 fcfa
                        </span>
                        <span className="short-text">
                          <b>Couscous blanc / au gras</b>
                          <br />
                          1000 / 1500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Petit
                          pois............................................................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Petit pois</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li id="rizBlanc">
                        <span className="full-text">
                          Riz
                          blanc.............................................................................1000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Riz blanc</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li id="rizCantonais">
                        <span className="full-text">
                          Riz au gras / Riz
                          cantonais................................1500 / 2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Riz au gras / Riz cantonais</b>
                          <br />
                          1500 / 2500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Djinkoumé poulet / pinon au
                          poulet...........................2500 fcfa
                        </span>
                        <span className="short-text">
                          <b>Djinkoumé poulet / pinon au poulet</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Sifio....................................................................................3500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Sifio</b>
                          <br />
                          3500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Moyo..................................................................................4000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Moyo</b>
                          <br />
                          4000 fcfa
                        </span>
                      </li>
                    </ul>
                    <div className="pakeg_title">
                      <h4>Nos Plats Africains</h4>
                    </div>
                    <ul>
                      <li>
                        <span className="full-text">
                          Pâte de mil / Ewokoumè / Emankoumè / Gboma / fétri /
                          Adémè.....................................................................1500
                          / 2000 fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            Pâte de mil / Ewokoumè / Emankoumè / Gboma / fétri /
                            Adémè
                          </b>
                          <br />
                          1500 / 2000 fcfa
                        </span>
                      </li>
                    </ul>
                    <div className="pakeg_title">
                      <h4>Nos Soupes</h4>
                    </div>
                    <ul>
                      <li>
                        <span className="full-text">
                          Soupe de boyaux de
                          boeuf.............................................1000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Soupe de boyaux de boeuf</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Soupe de
                          chèvre..............................................................2500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Soupe de chèvre</b>
                          <br />
                          2500 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Soupe de patte de boeuf / escargots sauté aux légumes
                          / rognon sauté aux légumes
                          ...........................................2000 fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            Soupe de patte de boeuf / escargots sauté aux
                            légumes / rognon sauté aux légumes
                          </b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                id="viandebrochette"
                className="single_pakeg_two text-left wow rotateInDownLeft"
              >
                <div className="col-md-6 col-sm-8">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Nos Brochettes / Viandes / Poulets / Poissons </h4>
                    </div>

                    <ul>
                      <li id="brochetteDeBoeuf">
                        <span className="full-text">
                          Brochette de boeuf (5 unités) / de blanc de
                          poulet....1000 fcfa
                        </span>
                        <span className="short-text">
                          <b>
                            Brochette de boeuf (5 unités) / de blanc de poulet
                          </b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li id="brochetteDeSaucisse">
                        <span className="full-text">
                          Brochette de saucisse (7
                          unités)...................................1000 fcfa
                        </span>
                        <span className="short-text">
                          <b>Brochette de saucisse (7 unités)</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          Boulette de
                          viande...............................................................500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Boulette de viande</b>
                          <br />
                          500 fcfa
                        </span>
                      </li>
                      <li id="poulet">
                        <span className="full-text">
                          Poulet 1/2 frire ou
                          grillé...................................................3500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Poulet 1/2 frire ou grillé</b>
                          <br />
                          3500 fcfa
                        </span>
                      </li>
                      <li id="pintade">
                        <span className="full-text">
                          Pintade 1/2 frire ou
                          grillé................................................4000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Pintade 1/2 frire ou grillé</b>
                          <br />
                          4000 fcfa
                        </span>
                      </li>
                      <li id="pouletYassa">
                        <span className="full-text">
                          1/2 poulet yassa / michoui de poulet............3500 /
                          4000 fcfa
                        </span>
                        <span className="short-text">
                          <b>1/2 poulet yassa / michoui de poulet</b>
                          <br />
                          3500 / 4000 fcfa
                        </span>
                      </li>
                      {/* <li id="aileDePoulet">
                        <span className="full-text">
                          Aile de
                          poulet......................................................................300
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Aile de poulet</b> <br />
                          300 fcfa
                        </span>
                      </li> */}
                      <li id="tilapia">
                        <span className="full-text">
                          Tilapia.......................................................3000
                          / 3500 / 4000 fcfa
                        </span>
                        <span className="short-text">
                          <b>Tilapia</b>
                          <br />
                          3000 / 3500 / 4000 fcfa
                        </span>
                      </li>
                      <li id="tilapia">
                        <span className="full-text">
                          Dorade.......................................................3500
                          / 4000 / 4500 fcfa
                        </span>
                        <span className="short-text">
                          <b>Dorade</b>
                          <br />
                          3500 / 4000 / 4500 fcfa
                        </span>
                      </li>
                      <li id="tilapia">
                        <span className="full-text">
                          Chinchard...............................................2500
                          / 3000 / 3500 fcfa
                        </span>
                        <span className="short-text">
                          <b>Chinchard</b>
                          <br />
                          2500 / 3000 / 3500 fcfa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                id="fastfood"
                className="single_pakeg_three text-left wow rotateInDownRight"
              >
                <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Nos Fast-food</h4>
                    </div>

                    <ul>
                      <li id="chawarma">
                        <span className="full-text">
                          Chawarma......................................................1200
                          / 1500 fcfa
                        </span>
                        <span className="short-text">
                          <b>Chawarma </b>
                          <br />
                          1200 / 1500 fcfa
                        </span>
                      </li>
                      <li id="doubleBurger">
                        <span className="full-text">
                          Hamburger /
                          Cheeseburger.....................................1200
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Hamburger / Cheeseburger</b>
                          <br />
                          1200 fcfa
                        </span>
                      </li>
                      <li id="pizza">
                        <span className="full-text">
                          Pizza............................................................................7000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Pizza</b>
                          <br />
                          7000 fcfa
                        </span>
                      </li>
                      <li id="sandwichPoulet">
                        <span className="full-text">
                          Sandwich
                          poulet.......................................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Sandwich poulet</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li id="sandwichViande">
                        <span className="full-text">
                          Sandwich
                          viande......................................................1500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Sandwich viande</b>
                          <br />
                          1500 fcfa
                        </span>
                      </li>
                      <li id="sandwichVegetarienne">
                        <span className="full-text">
                          Sandwich
                          vegetarienne............................................2000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Sandwich vegetarienne</b>
                          <br />
                          2000 fcfa
                        </span>
                      </li>
                      <li>
                        <span className="full-text">
                          <b>
                            Accompagnement : Aloco, frite, akpan, atchieke,
                            igname frite{' '}
                          </b>
                        </span>
                        <span className="short-text">
                          <b>
                            Accompagnement : Aloco, frite, akpan, atchieke,
                            igname frite{' '}
                          </b>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                id="dessert"
                className="single_pakeg_two text-left wow rotateInDownLeft"
              >
                <div className="col-md-6 col-sm-8">
                  <div className="single_pakeg_text">
                    <div className="pakeg_title">
                      <h4>Nos Desserts </h4>
                    </div>

                    <ul>
                      <li id="jusDeFruits">
                        <span className="full-text">
                          Jus de
                          fruit..................................................................500
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Jus de fruit</b>
                          <br />
                          500 fcfa
                        </span>
                      </li>
                      <li id="yaourt">
                        <span className="full-text">
                          Yaourt.......................................................................1000
                          fcfa
                        </span>
                        <span className="short-text">
                          <b>Yaourt</b>
                          <br />
                          1000 fcfa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="ourPakegCommandLink">
                <CommandLink
                  label="Commandez maintenant"
                  command="un de vos plats"
                />{' '}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurPakeg
