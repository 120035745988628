import { allVariables } from '../../utils/allVariables'

function CommandLink({ label, command }) {
  const text = 'Je veux commander : ' + command
  const message = encodeURIComponent(text)
  return (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          'https://wa.me/' + allVariables.commandNumber + '?text=' + message
        }
        className="btn btn-primary"
      >
        {label}
      </a>
    </>
  )
}

export default CommandLink
