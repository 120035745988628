import './Header.css'

function Header() {
  return (
    <>
      {/* <div className="preloader">
        <div className="loaded">&nbsp;</div>
      </div> */}
      <header id="home" className="navbar-fixed-top">
        <div className="header_top_menu clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-offset-3 col-sm-12 text-right">
                <div className="call_us_text">
                  <a href="/">
                    <i className="fa fa-clock-o"></i>Commande 24/7
                  </a>
                  <a href="tel:90008816">
                    <i className="fa fa-phone"></i>90008816{' '}
                  </a>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="head_top_social text-right">
                  {/* <!--<a href=""><i className="fa fa-facebook"></i></a>
                                <a href=""><i className="fa fa-google-plus"></i></a>
                                <a href=""><i className="fa fa-twitter"></i></a>
                                <a href=""><i className="fa fa-linkedin"></i></a>
                                <a href=""><i className="fa fa-pinterest-p"></i></a>
                                <a href=""><i className="fa fa-youtube"></i></a>
                                <a href=""><i className="fa fa-phone"></i></a>
                                <a href=""><i className="fa fa-camera"></i></a>--> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- End navbar-collapse--> */}

        <div className="main_menu_bg">
          <div className="container">
            <div className="row">
              <nav className="navbar navbar-default">
                <div className="container-fluid">
                  {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a id="navlogo" className="navbar-brand our_logo" href="/#">
                      <img src="assets/images/logo.png" alt="" />
                    </a>
                  </div>

                  {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                  <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul className="nav navbar-nav navbar-right">
                      <li>
                        <a href="#slider">Accueil</a>
                      </li>
                      <li>
                        <a href="#abouts">A propos</a>
                      </li>
                      <li>
                        <a href="#features">Spécialité</a>
                      </li>
                      <li>
                        <a href="#portfolio">Menu</a>
                      </li>
                      <li>
                        <a href="#ourPakeg">Nos Packages</a>
                      </li>
                      <li>
                        <a href="#footer_widget">Contact</a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /.navbar-collapse --> */}
                </div>
                {/* <!-- /.container-fluid --> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- End Header Section --> */}
    </>
  )
}

export default Header
