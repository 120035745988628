import emailjs from 'emailjs-com'
import { useState } from 'react'
import './Footer.css'

function Footer() {
  const [input, setInput] = useState('')
  const [input2, setInput2] = useState('')
  const [input3, setInput3] = useState('')
  const [response, setResponse] = useState('')

  function sendEmail(e) {
    e.preventDefault()
    console.log(input.length)
    console.log(input2)
    console.log(input3)
    if (input.length > 0 && input2.length > 0 && input3.length > 0) {
      emailjs
        .sendForm(
          'service_vd6lztp',
          'template_zuu4tnm',
          e.target,
          'KeLsRrKZlU0rBRbMC'
        )
        .then(
          (result) => {
            // console.log(result.text)
            setResponse(
              <span>
                Message envoyé, Nous vous recontacterons{' '}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={() => setResponse('')}
                >
                  ok
                </button>
                <br />
              </span>
            )
            setInput('')
            setInput2('')
            setInput3('')
          },
          (error) => {
            // console.log(error.text)
            setResponse(<span>Erreur, réessayez plus tard</span>)
          }
        )
    } else {
      setResponse(<span>Veuillez remplir les champs</span>)
    }
  }

  return (
    <>
      <section id="footer_widget" className="footer_widget">
        <div className="container">
          <div className="row">
            <div className="footer_widget_content text-center">
              <div className="col-md-4">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="2s"
                >
                  <h3>Détendez-vous nous livrons</h3>

                  <div className="single_widget_info">
                    <p>
                      Avédji carrefour Y, 3ème von à droite
                      <span>après le feu tricolore en quittant</span>
                      <span>
                        carrefour Y vers la douane Adidogomé, à 100m du goudron
                      </span>
                      <span className="phone_email">Téléphone: 90008816 </span>
                      <span>Email: info@restaurant-tontonlajoie.com</span>
                    </p>
                  </div>

                  <div className="footer_socail_icon">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/Tonton-la-joie-104556642292637"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/wScVp2ZaAabAiVHV8"
                    >
                      <i className="fa fa-google-plus"></i>
                    </a>
                    {/* <a href="/">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-linkedin"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-pinterest-p"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-youtube"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-phone"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-camera"></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="4s"
                >
                  {/* <h3></h3> */}

                  <div id="openHours" className="single_widget_info">
                    <p>
                      <span className="date_day">Tous les jours (7j / 7)</span>
                      <span>De 10h00 à 3h00 du matin</span>

                      <span className="date_day">Tous les weekends</span>
                      <span>Promotion</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" id="greatDivFormid">
                <div
                  className="single_widget wow fadeIn"
                  data-wow-duration="5s"
                >
                  {/* <h3></h3> */}

                  <div className="single_widget_form text-left">
                    <form id="formid" onSubmit={sendEmail}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Nom"
                          required=""
                          value={input}
                          onInput={(e) => setInput(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="contact"
                          placeholder="Email / Téléphone"
                          required=""
                          value={input2}
                          onInput={(e) => setInput2(e.target.value)}
                        />
                      </div>

                      {/* <!-- <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Subject">
                                        </div> --> <!-- end of form-group --> */}

                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="3"
                          placeholder="Message"
                          value={input3}
                          onInput={(e) => setInput3(e.target.value)}
                        ></textarea>
                      </div>
                      <div>{response}</div>

                      <input
                        type="submit"
                        value="Envoyez"
                        className="btn btn-primary"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Footer--> */}
      <footer id="footer" className="footer">
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright wow zoomIn" data-wow-duration="3s">
                <p> © Copyriht. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
