import { commandClick } from '../../../../utils/commandClick'
function Slider() {
  //   function commandClick(text) {
  //     const message = encodeURIComponent(text)
  //     window.location.replace('https://wa.me/22890030695?text=' + message)
  //   }

  return (
    <>
      <section id="slider" className="slider">
        <div className="slider_overlay">
          <div className="container">
            <div className="row">
              <div className="main_slider text-center">
                <div className="col-md-12">
                  <div
                    className="main_slider_content wow zoomIn"
                    data-wow-duration="1s"
                  >
                    <h1 className="full-resto-name">
                      Restaurant <br /> TONTON <br /> LA <br /> JOIE
                    </h1>
                    <span className="short-resto-name">
                      Restaurant <br /> TONTON <br /> LA <br /> JOIE
                    </span>
                    <p>
                      Vous êtes ici chez vous. Savourez les meilleurs de nos
                      plats, dégustez les meilleures de nos boissons{' '}
                    </p>
                    <button
                      href=""
                      className="btn-lg"
                      onClick={() => commandClick('un de vos plats ')}
                    >
                      Commandez maintenant
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Slider
