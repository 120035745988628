import CommandLink from '../../../../components/CommandLink/CommandLink'

function Features() {
  return (
    <>
      <section id="features" className="features">
        <div className="slider_overlay">
          <div className="container">
            <div className="row">
              <div
                className="main_features_content_area  wow fadeIn"
                data-wow-duration="3s"
              >
                <div className="col-md-12">
                  <div className="main_features_content text-left">
                    <div className="col-md-6">
                      <div className="single_features_text">
                        <h4>Spécialité de la maison</h4>
                        <h3>Savourez</h3>
                        <p>
                          <b>Spaghetti tonton la joie</b> composé
                          d'oeuf,saucisse, mayonnaise et légumes (1500 fcfa)
                        </p>
                        <p>
                          <b>Chawarma tonton la joie</b> composé de fromage,
                          viande, poulet pimenté (1500 fcfa).
                        </p>
                        <p>
                          <b>Tchimbani tonton la joie</b> (1000 fcfa).
                        </p>
                        <p>
                          <b>Pizza tonton la joie</b> je vous laisse découvrir
                          la composition (7000 fcfa).
                          <br /> A tout moment commandez, savourez et
                          faites-nous vos retours.
                        </p>

                        <CommandLink
                          label="Commandez maintenant"
                          command="une spécialité de la maison"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
